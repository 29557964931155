// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("dropzone");
require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import "markdown";
import "@oddcamp/cocoon-vanilla-js";
import "select2";
import sortable from "html5sortable/dist/html5sortable.es.js";

document.addEventListener("turbolinks:load", function () {
  Dropzone.discover();
});

Dropzone.options.newImage = {
  paramName: "image[file]",
  acceptedFiles: "image/*",
  dictDefaultMessage: "Drop afbeeldingen hier om te uploaden",
};

// Melding bij verlaten document
window.unsaved = false;
window.unsaved_msg =
  "Let op: Er zijn wijzigingen gemaakt op deze pagina. Deze wijzigingen zijn niet opgeslagen. Druk op annuleren als je deze wijzigingen eerst nog wilt opslaan.";

$(document).on("change", "form:not([data-remote]) :input", function () {
  window.unsaved = true;
});

$(document).on("turbolinks:load", function () {
  window.unsaved = false;
});

$(document).on("submit", "form", function () {
  window.unsaved = false;
});

$(window).on("beforeunload", function () {
  if (window.unsaved) {
    return window.unsaved_msg;
  }
});

$(document).on("turbolinks:before-visit", function (event) {
  if (window.unsaved && !confirm(window.unsaved_msg)) {
    event.preventDefault();
  }
});

// Type materiaal switch bij form
var update_publication_material_type_visibility = function () {
  $(".publication-fields").each(function () {
    let material_type = $("select[name*='material_type']", this).val();

    $(".d-material-type", this).removeClass("d-none");
    $(
      ".d-material-type:not(.d-material-type-" + material_type + ")",
      this
    ).addClass("d-none");
  });
};

$(document).on(
  "turbolinks:load cocoon:after-insert",
  update_publication_material_type_visibility
);

$(document).on(
  "change",
  ".publication_child_publications_material_type",
  update_publication_material_type_visibility
);

$(document).on("click", ".select-child-material-type .btn", function (event) {
  event.preventDefault();
  event.stopPropagation();

  const target = $(this);
  const container = target.parents(".select-child-material-type:first");
  const child = container.parents(".publication-fields:first");

  container
    .find(".btn.btn-primary")
    .removeClass("btn-primary")
    .addClass("btn-outline-primary");

  target.addClass("btn-primary").removeClass("btn-outline-primary");

  $(".publication_child_publications_material_type select", child).val(
    target.data("material-type")
  );

  container.addClass("mb-3");

  update_publication_material_type_visibility();
});

import { Loader } from "@googlemaps/js-api-loader";
var map_loaded = false;
const loader = new Loader({
  apiKey: "AIzaSyD-zNBxSF5O-s7-oKnBSXROPD0W4qrLKHw",
  version: "weekly",
});

loader.load().then(() => {
  map_loaded = true;
  update_maps();
});

var update_maps = function () {
  if (!map_loaded) {
    return;
  }

  $(".map:not(.loaded)").each(function () {
    var data = $(this).data("churches");

    var map = new google.maps.Map(this, {
      center: { lat: 52.2093655, lng: 4.1584481 },
      zoom: 8,
      maxZoom: 12,
    });

    var bounds = new google.maps.LatLngBounds();

    $.each(data, function () {
      if (!this.latitude || !this.longitude) {
        return;
      }

      // console.log(this);
      let marker = new google.maps.Marker({
        position: {
          lat: parseFloat(this.latitude),
          lng: parseFloat(this.longitude),
        },
        map: map,
        title: this.name,
      });

      let infowindow = new google.maps.InfoWindow({
        content: this.name,
      });

      marker.addListener("click", () => {
        infowindow.open({ anchor: marker, map, shouldFocus: false });
      });

      bounds.extend(marker.position);
    });

    map.fitBounds(bounds);
  });
};

$(document).on("turbolinks:load", update_maps);

function formatSelect2Template(item) {
  if (!item.element || !item.element.dataset.depth) {
    return item.text;
  }

  var $option = $("<div></div>")
    .css("paddingLeft", item.element.dataset.depth + "em")
    .text(item.text);
  return $option;
}

function init_select2() {
  $("select").select2({
    theme: "bootstrap",
    templateResult: formatSelect2Template,
  });
}

$(document).on("turbolinks:load cocoon:after-insert", init_select2);

$(document).on("turbolinks:load", function () {
  $(".card.subnavigation .card-body").on("scroll", function () {
    var subnavigationScrollTop = $(this).scrollTop();
    localStorage.setItem("subnavigationScrollTop", subnavigationScrollTop);
  });

  if (localStorage.subnavigationScrollTop) {
    $(".card.subnavigation .card-body").scrollTop(
      localStorage.getItem("subnavigationScrollTop")
    );
  }
});

// Collapseable archive tree
$(document).on("turbolinks:load", function () {
  // 1. Remove collapse-state for items that are selected
  $(".collapseable-list ul.d-none").each(function () {
    if (
      this.previousElementSibling &&
      this.previousElementSibling.classList.contains("active")
    ) {
      this.classList.remove("d-none");
    }
  });

  // 2. Remove collapse-state for items that are openend by the user
  $(".collapseable-list ul.d-none").each(function () {
    if (
      localStorage.collapseableList &&
      localStorage.collapseableList.includes(this.id)
    ) {
      this.classList.remove("d-none");
    }
  });

  // 3. Observe changes
  $(".collapseable-list .toggle-collapse").on("click", function () {
    if (!localStorage.collapseableList) {
      localStorage.setItem("collapseableList", JSON.stringify([]));
    }

    let collapseableList = JSON.parse(localStorage.collapseableList);
    let collapseable = $(this).parents("li:first").find("> ul").get(0);

    if (collapseable.classList.contains("d-none")) {
      collapseable.classList.remove("d-none");
      collapseableList[collapseableList.length] = collapseable.id;
    } else {
      collapseable.classList.add("d-none");

      let index = collapseableList.indexOf(collapseable.id);
      if (index > -1) {
        collapseableList.splice(index, 1);
      }
    }

    localStorage.setItem("collapseableList", JSON.stringify(collapseableList));
  });
});

document.addEventListener("turbolinks:load", function () {
  let sortables = sortable(".sortable", {
    handle: ".handle",
    placeholderClass: "placeholder",
  });

  $.each(sortables, function () {
    this.addEventListener("sortupdate", function (e) {
      let container = e.detail.origin.container;
      let positions = [];

      $.each(container.children, function () {
        positions[positions.length] = {
          id: this.dataset.id,
          type: this.dataset.type,
        };
      });

      $.ajax(container.dataset.repositionPath, {
        method: "patch",
        data: { positions: positions },
        headers: {
          authenticity_token: $('meta[name="csrf-token"]').attr("content"),
        },
      });
    });
  });
});
