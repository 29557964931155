import EasyMDE from "easymde";

$(document).on("turbolinks:load cocoon:after-insert", function () {
  const elements = document.querySelectorAll(
    "textarea.markdown:not(.converted)"
  );

  $.each(elements, function () {
    $(this).addClass("converted");
    new EasyMDE({
      element: this,
      forceSync: true,
      spellChecker: false,
      toolbar: [
        "bold",
        "italic",
        "heading",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "|",
        "link",
        "|",
        "preview",
        "side-by-side",
        "fullscreen",
        "|",
        "undo",
        "redo",
      ],
    });
  });
});
